<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>REPORTE DE ESTADO DE FINANCIONES</h4>
      </div>
    </div>

    <SelectSpecialty
      v-model="specialty_id"
      @input="getList()"
    ></SelectSpecialty>

    <!-- <app-ctrl-year-month
      ref="elCtrlYM"
      @input="getList()"
    ></app-ctrl-year-month> -->

    <!-- <app-ctrl-ym-progresive
      ref="elCtrlYM"
      @input="getList()"
    ></app-ctrl-ym-progresive> -->

    <div class="table-responsive">
      <table class="table table-bordered">
        <tr class="d-fixed position-sticky">
          <td>
            <button class="btn btn-light" @click="handleNavigateClick(-1)">
              <i class="fa fa-arrow-left"></i>
            </button>
            <button class="btn btn-light" @click="handleNavigateClick(1)">
              <i class="fa fa-arrow-right"></i>
            </button>
          </td>
          <th
            v-for="(l, i) in times"
            :key="i"
            class="text-center text-uppercase"
          >
            <span>{{ l.year }} {{ l.currMonth }}</span>
          </th>
        </tr>
        <tr>
          <td></td>
          <td v-for="(l, i) in totals" :key="i" class="text-right">
            <!-- <div>{{ l.year }}-{{ l.month }}</div> -->
            <div class="text-nowrap">
              <app-span-money
                :quantity="l.amount"
                :moneyCode="1"
              ></app-span-money>
            </div>
            <div>
              <app-span-money
                :quantity="l.amount_paid"
                :moneyCode="1"
              ></app-span-money>
            </div>
          </td>
        </tr>
        <tr>
          <td>TOTAL</td>
          <td>{{ list.length }}</td>
        </tr>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            <router-link :to="`/dental-clinic/financings/${l.financing_id}`">
              #{{ l.financing_id }}
            </router-link>
            <br />
            <router-link :to="`/dental-clinic/patients/${l.client_id}`"
              >{{ l.client_name }}
            </router-link>
          </td>
          <td v-for="(l1, i1) in l.nextDues" :key="i1">
            <div
              :title="l1.reason"
              v-if="l1"
              :class="{
                'bg-success': l1.amount_paid >= l1.amount,
                'bg-danger': l1.amount_paid < l1.amount
              }"
              class="text-white"
            >
              <app-span-money
                :quantity="l1.amount"
                :moneyCode="l.money_code"
              ></app-span-money>
              <br />
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td v-for="(l, i) in totals" :key="i" class="text-right text-nowrap">
            <div>{{ l.year }}-{{ l.month }}</div>
            <div>
              <app-span-money
                :quantity="l.amount"
                :moneyCode="1"
              ></app-span-money>
            </div>
            <div>
              <app-span-money
                :quantity="l.amount_paid"
                :moneyCode="1"
              ></app-span-money>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
// import { ServiceFactory } from "../../service-module/service";
import SelectSpecialty from "../../general-module/specialties/Select";

import { myMoment } from "../../utils/myMoment";
import { DentalClinicService } from "../service";
// import {DE } from "../service"

export default {
  components: {
    SelectSpecialty
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: [],
    specialty_id: null,
    totals: [],
    times: [],
    date: {}
    // enables: 0,
    // pasives: 0
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  created() {
    this.date = {
      year: myMoment().year(),
      month: myMoment().month() + 1
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    handleNavigateClick(val) {
      let thisTime = myMoment(`${this.date.year}-${this.date.month}`, "YYYY-M");
      thisTime.add(val, "months");
      this.date = {
        year: thisTime.year(),
        month: thisTime.month() + 1
      };

      this.getList();
    },

    getList(params) {
      DentalClinicService.reportFinancingDues({
        // ...this.$refs.elCtrlYM.getValue(),
        ...this.date,
        specialty_id: this.specialty_id,
        ...params
      }).then((data) => {
        // console.log(res);
        this.list = data.financings;
        this.totals = data.totals;
        this.times = data.times.map((x) => ({
          ...x,
          currMonth: myMoment(x.month, "M").format("MMMM")
        }));
      });
    }
  }
};
</script>

<style></style>
